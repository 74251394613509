import React from 'react';
import styled from '@emotion/styled';

const Logo = () => {
  const LogoSvg = styled.svg`
    .cls-1 {
      fill: #63c196;
    }

    .cls-1,
    .cls-2,
    .cls-3,
    .cls-5 {
      fill-rule: evenodd;
    }

    .cls-2 {
      fill: #b7d770;
    }

    .cls-3 {
      fill: #e0e55d;
    }

    .cls-4 {
      fill: #641f5c;
    }

    .cls-5 {
      fill: #fff;
    }
  `;

  return (
    <LogoSvg
      data-name="Layer 1"
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 307.64 53.04"
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1-2">
          <polygon
            className="cls-1"
            points="32.31 19 47.31 13.99 52.31 46.78 32.31 53.04 32.31 19"
          />
          <polygon
            className="cls-2"
            points="32.31 19 17.31 13.99 12.31 46.78 32.31 53.04 32.31 19"
          />
          <polygon
            className="cls-1"
            points="8.19 17.08 17.31 13.99 12.31 46.78 0 49.82 8.19 17.08"
          />
          <polygon
            className="cls-3"
            points="56.41 17.08 47.29 13.99 52.29 46.78 64.6 49.82 56.41 17.08"
          />
          <path
            className="cls-4"
            d="M45.83,13.51A13.52,13.52,0,1,0,32.28,27h0A13.5,13.5,0,0,0,45.83,13.51"
          />
          <path
            className="cls-4"
            d="M32.31,26.11l-10.44-4,8.88,10A7.15,7.15,0,0,1,32.31,35a7.28,7.28,0,0,1,1.57-3l8.88-9.95Z"
          />
          <path
            className="cls-5"
            d="M37.73,12.88a5.42,5.42,0,1,0-5.42,5.42,5.42,5.42,0,0,0,5.42-5.42"
          />
          <path
            className="cls-4"
            d="M84.83,17.57a7.05,7.05,0,0,0-3.73-1.12c-1.47,0-2.51.58-2.51,1.58s1.13,1.26,2.71,1.52l.74.12c2.35.38,4.18,1.39,4.18,3.68,0,2.9-2.55,4.28-5.37,4.28a9.17,9.17,0,0,1-5.54-1.72l1.36-2.11a7.06,7.06,0,0,0,4.19,1.42c1.45,0,2.58-.58,2.58-1.59s-1-1.25-2.79-1.54L79.82,22c-2.32-.37-4-1.39-4-3.76C75.81,15.46,78.25,14,81,14a9.2,9.2,0,0,1,5,1.4Z"
          />
          <path
            className="cls-4"
            d="M97.17,27.39H94.78v-1a3.33,3.33,0,0,1-2.72,1.24A4.52,4.52,0,0,1,87.65,23c0-.06,0-.12,0-.18a4.49,4.49,0,0,1,4.21-4.77h.19a3.3,3.3,0,0,1,2.72,1.25v-1h2.39Zm-7-4.55a2.36,2.36,0,1,0,4.7.43,3.09,3.09,0,0,0,0-.43,2.35,2.35,0,0,0-4.7,0h0"
          />
          <path
            className="cls-4"
            d="M114.2,21.6v5.79h-2.44v-5c0-1.31-.66-2-1.77-2a1.86,1.86,0,0,0-1.93,1.79,2.26,2.26,0,0,0,0,.26v5h-2.45v-5c0-1.31-.66-2-1.78-2a1.86,1.86,0,0,0-1.93,1.79,2.26,2.26,0,0,0,0,.26v5H99.47V18.3h2.38v1.07a3.05,3.05,0,0,1,2.68-1.31,3.46,3.46,0,0,1,3,1.55,3.45,3.45,0,0,1,3.09-1.55,3.39,3.39,0,0,1,3.58,3.17,2.29,2.29,0,0,1,0,.37"
          />
          <path
            className="cls-4"
            d="M124.88,22.83a5.75,5.75,0,0,1,0,.83h-6.45a2.2,2.2,0,0,0,2.34,1.89,3.65,3.65,0,0,0,2.3-.88l1.43,1.58a5.49,5.49,0,0,1-3.87,1.38,4.49,4.49,0,0,1-4.72-4.27,4,4,0,0,1,0-.49,4.48,4.48,0,0,1,4.14-4.8h.46c2.61,0,4.41,1.94,4.42,4.77M118.39,22h4a2,2,0,1,0-4,0"
          />
          <path
            className="cls-4"
            d="M135.2,21.6v5.79h-2.45v-5c0-1.31-.66-2-1.78-2A1.86,1.86,0,0,0,129,22.18a2.26,2.26,0,0,0,0,.26v5H126.6V18.3H129v1.07a3,3,0,0,1,2.67-1.31,3.39,3.39,0,0,1,3.54,3.23v.31"
          />
          <polygon
            className="cls-4"
            points="147.41 24.68 149.37 18.3 151.93 18.3 148.85 27.39 146.06 27.39 144.04 21.25 142.04 27.39 139.26 27.39 136.18 18.3 138.79 18.3 140.73 24.65 142.77 18.3 145.35 18.3 147.41 24.68"
          />
          <path
            className="cls-4"
            d="M161.46,22.83a7.7,7.7,0,0,1-.05.83H155a2.2,2.2,0,0,0,2.34,1.89,3.65,3.65,0,0,0,2.3-.88L161,26.25a5.49,5.49,0,0,1-3.87,1.38,4.49,4.49,0,0,1-4.72-4.27,4,4,0,0,1,0-.49,4.48,4.48,0,0,1,4.14-4.8H157c2.61,0,4.41,1.94,4.42,4.77M155,22h4a2,2,0,0,0-4,0"
          />
          <path
            className="cls-4"
            d="M169.39,18.25,169,20.68a4.15,4.15,0,0,0-1.48-.29,1.82,1.82,0,0,0-1.93,1.7,2,2,0,0,0,0,.35v4.95h-2.45V18.3h2.38v1a2.86,2.86,0,0,1,2.56-1.24,3.66,3.66,0,0,1,1.27.19"
          />
          <polygon
            className="cls-4"
            points="173.24 21.54 174.11 21.54 176.67 18.3 179.67 18.3 176.09 22.58 179.7 27.39 176.69 27.39 174.07 23.71 173.24 23.71 173.24 27.39 170.79 27.39 170.79 13.88 173.24 13.88 173.24 21.54"
          />
          <path
            className="cls-4"
            d="M183.58,15.33A1.57,1.57,0,1,1,182,13.75a1.57,1.57,0,0,1,1.58,1.58v0m-.33,12.06H180.8V18.3h2.45Z"
          />
          <path
            className="cls-4"
            d="M194.15,21.6v5.79H191.7v-5c0-1.31-.66-2-1.78-2A1.86,1.86,0,0,0,188,22.18a1.77,1.77,0,0,0,0,.23v5h-2.45V18.3h2.38v1.07a3.05,3.05,0,0,1,2.68-1.31,3.39,3.39,0,0,1,3.54,3.23v.31"
          />
          <path
            className="cls-4"
            d="M202.81,19.31v-1h2.4v8.34c0,2.92-1.91,4.69-4.91,4.69a7.25,7.25,0,0,1-4-1.1l.87-2a5.45,5.45,0,0,0,3,.93,2.33,2.33,0,0,0,2.58-2,2.41,2.41,0,0,0,0-.38v-.64a3.43,3.43,0,0,1-2.8,1.23,4.36,4.36,0,0,1-4.2-4.52.49.49,0,0,0,0-.12,4.36,4.36,0,0,1,4.06-4.64H200a3.45,3.45,0,0,1,2.82,1.25m-4.49,3.39a2.29,2.29,0,1,0,4.57.3,1.5,1.5,0,0,0,0-.3,2.29,2.29,0,1,0-4.57-.3,1.5,1.5,0,0,0,0,.3"
          />
          <path
            className="cls-4"
            d="M214,20.83a7.49,7.49,0,0,0-2.9-.74c-.93,0-1.4.33-1.4.83s.66.66,1.48.78l.81.12c2,.3,3.07,1.18,3.07,2.72,0,1.88-1.54,3.09-4.18,3.09a7.23,7.23,0,0,1-4-1.07l1-1.86a5.34,5.34,0,0,0,3.1.89c1.14,0,1.68-.32,1.68-.85s-.46-.69-1.51-.84l-.74-.1c-2.1-.29-3.14-1.21-3.14-2.76,0-1.87,1.44-3,3.83-3a8.08,8.08,0,0,1,3.8.86Z"
          />
          <polygon
            className="cls-4"
            points="222.03 27.39 219.21 27.39 215.61 18.3 218.28 18.3 220.63 24.81 223.01 18.3 225.59 18.3 222.03 27.39"
          />
          <path
            className="cls-4"
            d="M235,22.83a5.75,5.75,0,0,1,0,.83h-6.45a2.2,2.2,0,0,0,2.34,1.89,3.67,3.67,0,0,0,2.3-.88l1.43,1.58a5.49,5.49,0,0,1-3.87,1.38A4.5,4.5,0,0,1,226,23.4a4.68,4.68,0,0,1,0-.53,4.48,4.48,0,0,1,4.14-4.8h.45c2.62,0,4.41,1.94,4.42,4.77M228.48,22h4a2,2,0,0,0-4,0"
          />
          <path
            className="cls-4"
            d="M242.9,18.25l-.36,2.43a4.2,4.2,0,0,0-1.49-.29,1.82,1.82,0,0,0-1.92,1.71,1.93,1.93,0,0,0,0,.34v4.95h-2.45V18.3h2.37v1a2.86,2.86,0,0,1,2.56-1.24,3.72,3.72,0,0,1,1.28.19"
          />
          <path
            className="cls-4"
            d="M253.82,22.84a4.5,4.5,0,0,1-4.22,4.78h-.18a3.35,3.35,0,0,1-2.73-1.24v1H244.3V13.88h2.44v5.37a3.26,3.26,0,0,1,2.67-1.19,4.51,4.51,0,0,1,4.42,4.58c0,.07,0,.13,0,.2m-7.2,0a2.35,2.35,0,1,0,4.68.43,3.09,3.09,0,0,0,0-.43,2.35,2.35,0,1,0-4.68-.43,3.09,3.09,0,0,0,0,.43"
          />
          <path
            className="cls-4"
            d="M264.49,27.39H262.1v-1a3.33,3.33,0,0,1-2.72,1.24A4.52,4.52,0,0,1,255,23c0-.06,0-.12,0-.18a4.49,4.49,0,0,1,4.21-4.77h.19a3.3,3.3,0,0,1,2.72,1.25v-1h2.39Zm-7-4.55a2.35,2.35,0,0,0,4.69.31,1.6,1.6,0,0,0,0-.31,2.35,2.35,0,1,0-4.69-.31,1.6,1.6,0,0,0,0,.31"
          />
          <path
            className="cls-4"
            d="M275.39,21.6v5.79h-2.45v-5c0-1.31-.66-2-1.78-2a1.86,1.86,0,0,0-1.93,1.79,2.26,2.26,0,0,0,0,.26v5h-2.45V18.3h2.38v1.07a3.05,3.05,0,0,1,2.68-1.31,3.39,3.39,0,0,1,3.54,3.23v.31"
          />
          <path
            className="cls-4"
            d="M284.12,19.25V13.88h2.44V27.39h-2.39v-1a3.33,3.33,0,0,1-2.72,1.24A4.52,4.52,0,0,1,277,23c0-.06,0-.12,0-.18a4.49,4.49,0,0,1,4.21-4.77h.19a3.26,3.26,0,0,1,2.67,1.19m-4.57,3.59a2.35,2.35,0,1,0,4.69.31,1.6,1.6,0,0,0,0-.31,2.35,2.35,0,0,0-4.69-.31,1.6,1.6,0,0,0,0,.31"
          />
          <path
            className="cls-4"
            d="M297.32,22.83a7.7,7.7,0,0,1-.05.83h-6.45a2.2,2.2,0,0,0,2.34,1.89,3.65,3.65,0,0,0,2.3-.88l1.43,1.58A5.5,5.5,0,0,1,293,27.63a4.49,4.49,0,0,1-4.72-4.27,4,4,0,0,1,0-.49,4.48,4.48,0,0,1,4.14-4.8h.45c2.62,0,4.42,1.94,4.43,4.77M290.83,22h4a2,2,0,0,0-4,0"
          />
          <path
            className="cls-4"
            d="M307.64,21.6v5.79h-2.45v-5c0-1.31-.66-2-1.78-2a1.86,1.86,0,0,0-1.93,1.79,2.26,2.26,0,0,0,0,.26v5H299V18.3h2.38v1.07a3.05,3.05,0,0,1,2.68-1.31,3.39,3.39,0,0,1,3.54,3.23v.31"
          />
          <path
            className="cls-4"
            d="M85,41.59a4.92,4.92,0,1,1-5.06-4.78h.13A4.74,4.74,0,0,1,85,41.36v.23m-7.34,0a2.42,2.42,0,1,0,2.41-2.5,2.38,2.38,0,0,0-2.41,2.35v.15"
          />
          <path
            className="cls-4"
            d="M96.48,41.59a4.5,4.5,0,0,1-4.22,4.78h-.18A3.36,3.36,0,0,1,89.4,45.2v4.7H87V37.05h2.4v1a3.28,3.28,0,0,1,2.71-1.25,4.51,4.51,0,0,1,4.42,4.58c0,.07,0,.13,0,.2m-7.2,0a2.28,2.28,0,1,0,0-.17c0,.06,0,.11,0,.17"
          />
          <path
            className="cls-4"
            d="M109.62,38V32.63h2.44V46.14h-2.39v-1A3.37,3.37,0,0,1,107,46.38a4.51,4.51,0,0,1-4.42-4.6c0-.06,0-.13,0-.19a4.49,4.49,0,0,1,4.21-4.77h.2A3.27,3.27,0,0,1,109.62,38m-4.57,3.59a2.28,2.28,0,1,0,0-.18c0,.06,0,.12,0,.18"
          />
          <path
            className="cls-4"
            d="M123.08,41.58a5.75,5.75,0,0,1,0,.83h-6.45A2.2,2.2,0,0,0,119,44.3a3.65,3.65,0,0,0,2.3-.88L122.65,45a5.49,5.49,0,0,1-3.87,1.38,4.49,4.49,0,0,1-4.72-4.27,4,4,0,0,1,0-.49,4.48,4.48,0,0,1,4.14-4.8h.46c2.61,0,4.41,1.94,4.42,4.77m-6.49-.84h4a2,2,0,0,0-4,0"
          />
          <polygon
            className="cls-4"
            points="132.15 40.29 133.02 40.29 135.58 37.05 138.58 37.05 135 41.33 138.61 46.14 135.6 46.14 132.98 42.47 132.15 42.47 132.15 46.14 129.71 46.14 129.71 32.63 132.15 32.63 132.15 40.29"
          />
          <path
            className="cls-4"
            d="M148.92,46.14h-2.39v-1a3.33,3.33,0,0,1-2.72,1.24,4.52,4.52,0,0,1-4.41-4.61c0-.06,0-.12,0-.18a4.49,4.49,0,0,1,4.21-4.77h.19a3.3,3.3,0,0,1,2.72,1.25v-1h2.39Zm-7-4.55a2.28,2.28,0,1,0,0-.18c0,.06,0,.12,0,.18"
          />
          <path
            className="cls-4"
            d="M160.43,46.14H158v-1a3.33,3.33,0,0,1-2.72,1.24,4.52,4.52,0,0,1-4.41-4.61c0-.06,0-.12,0-.18a4.49,4.49,0,0,1,4.21-4.77h.19A3.3,3.3,0,0,1,158,38.06v-1h2.39Zm-7-4.55a2.28,2.28,0,1,0,0-.17c0,.06,0,.11,0,.17"
          />
          <path
            className="cls-4"
            d="M169.21,37l-.37,2.43a3.94,3.94,0,0,0-1.48-.29,1.82,1.82,0,0,0-1.93,1.71c0,.09,0,.19,0,.29v5H163V37.05h2.38v1a2.86,2.86,0,0,1,2.56-1.24,3.72,3.72,0,0,1,1.28.19"
          />
          <path
            className="cls-4"
            d="M177.47,39.23h-3.3v3.32c0,1.13.6,1.55,1.36,1.55a3.61,3.61,0,0,0,1.71-.59l.9,1.91a5.14,5.14,0,0,1-3,1c-2.25,0-3.41-1.28-3.41-3.63V39.23H170V37.05h1.75v-2.7h2.45v2.7h3.3Z"
          />
        </g>
      </g>
    </LogoSvg>
  );
};

export default Logo;
