import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import {
  concat,
  filter,
  find,
  has,
  map,
  not,
  pathEq,
  pickAll,
  pipe,
  prop,
  path,
  propEq,
  propOr,
  reverse,
  uniqBy,
} from 'ramda';

const createFallbackTag = (name, content) => ({
  tagName: 'meta',
  attributes: {
    name,
    content,
  },
});

const fallbackMap = {
  title: 'title',
  description: 'description',
  twitterCard: 'twitter:card',
};

const createFallbackTags = ({ fallbackSeo }) =>
  map(
    key => createFallbackTag(fallbackMap[key], fallbackSeo[key]),
    Object.keys(fallbackSeo)
  );

const getDescription = (tags, { fallbackSeo }) => {
  const tag = find(propEq('tagName', 'description'), tags);
  const fallbackTag = prop('description', fallbackSeo);
  return tag || fallbackTag;
};

const onlyMeta = tag => {
  if (not(has('attributes', tag))) {
    return false;
  }
  return pipe(pathEq(['attributes', 'name'], 'title'), not)(tag);
};

const formatNames = map(({ tagName, attributes: { name = '', ...rest } }) => ({
  tagName,
  attributes: {
    name: name.replace('twitter', 'og'),
    ...rest,
  },
}));

export const SEO = ({
  tags = [],
  meta = [],
  globalSeo = { fallbackSeo: {} },
  lang = 'nl',
}) => {
  const title = find(propEq('tagName', 'title'), tags);
  const description = getDescription(tags, globalSeo);
  const siteName = prop('siteName', globalSeo);
  const fallbackTags = createFallbackTags(globalSeo);
  const metaTags = uniqBy(
    path(['attributes', 'name']),
    reverse(formatNames(filter(onlyMeta, concat(fallbackTags, tags, meta))))
  );

  return (
    <Helmet
      defer={false}
      htmlAttributes={{ lang }}
      title={propOr('', 'content', title)}
      description={description}
      defaultTitle={propOr(
        'Samenwerkingsverbanden op de kaart',
        'siteName',
        globalSeo
      )}
      titleTemplate={`%s | ${siteName}`}
    >
      {map(
        ({ attributes }) => (
          <meta key={`${attributes.name}-${Date.now()}`} {...attributes} />
        ),
        metaTags
      )}
    </Helmet>
  );
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagName: PropTypes.string,
      content: PropTypes.string,
    })
  ),
  globalSeo: PropTypes.shape({
    siteName: PropTypes.string.isRequired,
    titleSuffix: PropTypes.string.isRequired,
    twitterAccount: PropTypes.string,
    facebookPageUrl: PropTypes.string,
    fallbackSeo: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
      twitterCard: PropTypes.string,
    }),
  }),
};

const Connected = ({ tags = [] }) => {
  const { datoCmsSite } = useStaticQuery(graphql`
    query SEOQuery {
      datoCmsSite {
        globalSeo {
          siteName
          titleSuffix
          twitterAccount
          facebookPageUrl
          fallbackSeo {
            description
            title
            twitterCard
          }
        }
      }
    }
  `);

  return <SEO globalSeo={propOr({}, 'globalSeo', datoCmsSite)} tags={tags} />;
};

Connected.propTypes = {
  ...pickAll(['description', 'lang', 'meta', 'title', 'tags'], SEO.propTypes),
};

export default Connected;
