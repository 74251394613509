import React from 'react';
import { complement, filter, propEq } from 'ramda';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Button from '@greenberry/salal/components/Button';
import ComparisonModal from '@greenberry/salal/components/ComparisonModal';
import {
  previewState,
  comparingSector,
  selectedForComparison,
} from '../../state/selectedForComparison';

const Wrapper = styled.div`
  & > div {
    z-index: 100;
  }

  ul > li span {
    width: 100%;
  }
`;
const CompareModal = () => {
  const partnerships = useRecoilValue(previewState);
  const sector = useRecoilValue(comparingSector);
  const setSelectedForComparison = useSetRecoilState(selectedForComparison);

  if (
    partnerships.length === 0 ||
    (global.window &&
      global.window.location &&
      global.window.location.pathname.includes('vergelijken'))
  ) {
    return null;
  }
  return (
    <Wrapper>
      <ComparisonModal
        partnerships={partnerships}
        onPartnershipRemove={item => {
          setSelectedForComparison(
            filter(complement(propEq('externalId', item.externalId)))
          );
        }}
        sector={sector
          .split('-')
          .map(x => x.substr(0, 1).toUpperCase())
          .join('')}
        cta={
          <Link to="/vergelijken" id="cta-to-compare">
            <Button
              color="primary"
              disabled={partnerships.length < 2}
              styling="solid"
              type="button"
              style={{ width: '100%' }}
            >
              <span style={{ width: '100%' }}>Naar vergelijken</span>
            </Button>
          </Link>
        }
      />
    </Wrapper>
  );
};

export default CompareModal;
