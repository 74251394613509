import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import Header from '@greenberry/salal/components/Header';
import HeaderSpacer from '@greenberry/salal/components/HeaderSpacer';
import Footer from '@greenberry/salal/components/Footer';
import theme from '@greenberry/salal/theme';

import Logo from '../Logo';
import SEO from '../SEO';
import CompareModal from '../CompareModal';
import globalStyles from './globalStyles';

const LogosWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${theme.spacing('l')};

  > *:first-of-type {
    margin-right: ${theme.spacing('l')};
  }
  & > svg,
  & > img {
    max-width: 200px;
  }
`;

function VO() {
  return (
    <svg
      enableBackground="new 0 0 294.5 94.4"
      viewBox="0 0 294.5 94.4"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g fill="#fff">
        <path
          d="m35.7 68.3 25.1-67.1h10.6l-35.7 93.2-35.7-93.2h10.6z"
          opacity=".8"
        />
        <path
          d="m78.7 34.1c0 14.9 12.1 27.3 27 27.3 15 0 27-12.4 27-27.3 0-15-11.9-27.3-27-27.3-15.1 0-27 12.4-27 27.3m61.5.1c0 19-15.6 34-34.4 34s-34.4-15.1-34.4-34 15.6-34.2 34.4-34.2 34.4 15.3 34.4 34.2"
          opacity=".8"
        />
        <path
          d="m159.6 20.5h1.3c4.8 0 9.6-.9 9.6-6.8 0-6.2-4.6-6.8-9.6-6.8h-1.3zm0 20.4h-4.2v-37.9h5.5c7.2 0 13.8 1.9 13.8 10.4 0 5.8-3.7 9.9-9.5 10.4l12 17h-5.2l-11.4-16.6h-1.1z"
          opacity=".8"
        />
        <path
          d="m201 11.1-6.5 15.2h12.8zm-8.2 19-4.6 10.7h-4.6l17.5-39.6 17 39.6h-4.7l-4.5-10.7z"
          opacity=".8"
        />
        <path
          d="m240.6 11.1-6.5 15.2h12.8zm-8.2 19-4.6 10.7h-4.6l17.5-39.6 17 39.6h-4.7l-4.5-10.7z"
          opacity=".8"
        />
        <path
          d="m273.6 36.9c4.6 0 8.5-.7 11.9-3.9 3.1-2.9 4.7-6.9 4.7-11.1 0-4.3-1.7-8.5-4.9-11.3-3.5-3.1-7.3-3.7-11.7-3.7h-3.1v30zm-7.3-33.9h7.1c5.7 0 10.3.8 14.8 4.6 4.3 3.7 6.3 8.8 6.3 14.4 0 5.5-2 10.4-6.2 14.1-4.5 4-9 4.8-15 4.8h-7z"
          opacity=".8"
        />
      </g>
    </svg>
  );
}

function PO() {
  return (
    <svg
      enableBackground="new 0 0 446.4 133.3"
      viewBox="0 0 446.4 133.3"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g fill="#fcfcfc" opacity=".8">
        <path d="m0 3.8c9-1.5 21.6-2.7 39.5-2.7 18 0 30.8 3.4 39.4 10.3 8.2 6.5 13.8 17.2 13.8 29.9 0 12.6-4.2 23.4-11.9 30.6-10 9.4-24.7 13.6-41.9 13.6-3.8 0-7.3-.2-10-.6v46.2h-28.9zm28.9 58.6c2.5.6 5.6.8 9.8.8 15.5 0 25.1-7.9 25.1-21.1 0-11.9-8.2-19-22.8-19-5.9 0-10 .6-12.1 1.1z" />
        <path d="m227.5 65.3c0 42.3-25.7 68-63.4 68-38.3 0-60.7-28.9-60.7-65.7 0-38.7 24.7-67.6 62.8-67.6 39.7 0 61.3 29.7 61.3 65.3m-93.3 1.7c0 25.3 11.9 43.1 31.4 43.1 19.7 0 31-18.8 31-43.9 0-23.2-11.1-43.1-31.2-43.1-19.7.1-31.2 18.8-31.2 43.9" />
        <path d="m243 6c3.5-.8 8.5-1.2 12.5-1.2 7.6 0 12.3 1.6 15.7 4.8 2.5 2.4 4.1 6.3 4.1 10.4 0 7.9-4.7 12.9-11.4 15.2v.2c4.7 1.5 7.6 5.9 9 12.3 2 9.1 3.1 13.4 4.3 15.7h-4.3c-.9-1.7-2.2-6.8-3.7-14.1-1.7-8.4-5.4-12.2-13-12.5h-9.1v26.7h-4.1zm4.1 27.3h9.2c9 0 14.8-5 14.8-12.7 0-8.9-6.6-12.5-15.7-12.5-4 0-6.9.4-8.4.8z" />
        <path d="m297.8 43-7.2 20.4h-4.2l21.1-58.2h4l21 58.2h-4.2l-7.4-20.4zm21.8-3.5-7.1-19.4c-1.4-4.1-2.2-7-3-10.3h-.2c-.9 3.4-1.8 6.4-3 10.1l-7.2 19.6z" />
        <path d="m352.6 43-7.2 20.4h-4.2l21.1-58.2h4l21 58.2h-4.3l-7.4-20.4zm21.8-3.5-7.1-19.4c-1.4-4.1-2.2-7-3-10.3h-.2c-.9 3.4-1.8 6.4-3 10.1l-7.2 19.6z" />
        <path d="m400.5 6c4.4-.7 9.4-1.2 14.8-1.2 10.6 0 18.6 2.9 23.5 7.9 4.7 4.8 7.2 11.3 7.2 20.1 0 8.5-2.3 16.3-7.6 22.1-5.2 5.6-13.9 8.9-25.1 8.9-4.5 0-8.7-.1-12.9-.5v-57.3zm4 53.9c2.4.3 5.8.4 9.4.4 18.6 0 27.9-10.4 27.9-27.3.1-14.8-8.1-24.8-26.7-24.8-4.5 0-8 .4-10.5.9z" />
        <path d="m242.2 72.2h60.2v60.2h-60.2z" />
        <path d="m314.2 72.2h60.2v60.2h-60.2z" />
        <path d="m386.1 72.2h60.2v60.2h-60.2z" />
      </g>
    </svg>
  );
}

const Layout = ({ children }) => {
  const { modularPage } = useStaticQuery(graphql`
    query getLayoutInfo {
      # Only fetch top level modular pages
      modularPage: allDatoCmsModulairePage(
        filter: { showInFooter: { eq: true } }
        sort: { order: ASC, fields: position }
      ) {
        edges {
          node {
            id
            title
            slug
          }
        }
      }
    }
  `);

  return (
    <>
      <Global styles={globalStyles} />
      <SEO />
      <Header
        logo={
          <Link to="/">
            <Logo />
          </Link>
        }
        navItems={[
          {
            icon: 'book',
            id: 'link 1',
            path: '/',
            text: 'Zoek op de kaart',
            // eslint-disable-next-line
            as: ({ ...props }) => <Link to="/kaart" {...props} />,
          },
          {
            icon: 'map',
            id: 'link 2',
            path: '/',
            text: 'Landelijk inzicht',
            // eslint-disable-next-line
            as: ({ ...props }) => <Link to="/landelijk-inzicht/" {...props} />,
          },
          {
            icon: 'chartBar',
            id: 'link 3',
            path: '/',
            text: 'Over passend onderwijs',
            // eslint-disable-next-line
            as: ({ ...props }) => <Link to="/over" {...props} />,
          },
        ]}
        subItems={
          [
            // {
            //   icon: 'search',
            //   id: 'search',
            //   path: '/',
            //   text: '',
            //   // eslint-disable-next-line
            //   as: ({ ...props }) => <Link to="/" {...props} />,
            // },
          ]
        }
      />
      <HeaderSpacer style={{ marginBottom: '0px' }} />
      {children}
      <CompareModal />
      <Footer
        columns={[
          {
            heading: 'Samenwerkingsverbanden op de kaart',
            items: [
              {
                id: '1',
                render: <Link to="/kaart">Zoek op de kaart</Link>,
              },
              {
                id: '2',
                render: <Link to="/landelijk-inzicht/">Landelijk inzicht</Link>,
              },
              {
                id: '3',
                render: <Link to="/over">Over passend onderwijs</Link>,
              },
              {
                id: '5',
                render: <Link to="/encyclopedie">Encyclopedie</Link>,
              },
              {
                id: '6',
                render: <Link to="/faq">Veelgestelde vragen</Link>,
              },
              {
                id: '4',
                render: <Link to="/contact">Contact</Link>,
              },
            ],
          },
          {
            heading: 'Over deze site',
            items: [
              ...modularPage.edges.map(({ node: { id, title, slug } }) => ({
                id,
                render: <Link to={`/${slug}`}>{title}</Link>,
              })),
            ],
          },
          {
            heading: 'Tot stand gekomen in opdracht van',
            items: [
              {
                id: '7',
                render: (
                  <LogosWrapper>
                    <PO />
                    <VO />
                  </LogosWrapper>
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
