import { css } from '@emotion/react';
import theme from '@greenberry/salal/theme';

export default css`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: ${theme.get('type.baseFontSize')};
    line-height: ${theme.get('type.lineHeight.base')};
    background: ${theme.color('secondary.3')};
  }

  body {
    font-family: ${theme.get('type.fontFamily.primary')};
    background: ${theme.color('secondary.3')};
  }

  a {
    text-decoration: none;
    color: ${theme.color('button.naked.primary.text')};
    transition: ${theme.get('transition.default.transition')};
    &:hover,
    &:active {
      color: ${theme.color('button.naked.primary.hover.text')};
    }
    &.tooltip {
      border-bottom: 2px dashed ${theme.color('button.naked.primary.text')};
      &:hover,
      &:active {
        border-color: ${theme.color('button.naked.primary.hover.text')};
      }
    }
  }

  .svg-inline--fa {
    height: 1.5rem;
  }
`;
